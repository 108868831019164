import { default as indexqElUDlwl5oMeta } from "/app/pages/articles/[id]/index.vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as indexzHrvlI7aRYMeta } from "/app/pages/auth/activate-account/token/index.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as tokenRCngmwVouZMeta } from "/app/pages/auth/reset-password/token.vue?macro=true";
import { default as signupHJ1GDnyyMkMeta } from "/app/pages/auth/signup.vue?macro=true";
import { default as indexE4RYMmRJ6cMeta } from "/app/pages/bundles/[id]/index.vue?macro=true";
import { default as index274LZELPy6Meta } from "/app/pages/bundles/index.vue?macro=true";
import { default as checkout4YA79jVi1xMeta } from "/app/pages/cart/checkout.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as order_statuswDWpkizgxOMeta } from "/app/pages/cart/order_status.vue?macro=true";
import { default as indexHatPuoyfPNMeta } from "/app/pages/end-year/index.vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as FAQhFYbLxOzyHMeta } from "/app/pages/FAQ.vue?macro=true";
import { default as index09YkL3vzCpMeta } from "/app/pages/group-application-request/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as indexpmB9uhUaIHMeta } from "/app/pages/materials/[id]/index.vue?macro=true";
import { default as indexgC0HPLjKhFMeta } from "/app/pages/materials/index.vue?macro=true";
import { default as indextQ4TumXCCAMeta } from "/app/pages/month-wallpapers/index.vue?macro=true";
import { default as indexsmHAEY1ihlMeta } from "/app/pages/monthly-notebook/index.vue?macro=true";
import { default as index4iD2z4Z5OwMeta } from "/app/pages/my-points/index.vue?macro=true";
import { default as indexA3oz4zcYnAMeta } from "/app/pages/pages/_id.vue/index.vue?macro=true";
import { default as aboutlNFxYDOD3BMeta } from "/app/pages/pages/about.vue?macro=true";
import { default as awareness_45dictionary8koniXMW7aMeta } from "/app/pages/pages/awareness-dictionary.vue?macro=true";
import { default as indexpmO3PCIeqvMeta } from "/app/pages/pages/index.vue?macro=true";
import { default as objectivesmJHYXKrqtvMeta } from "/app/pages/pages/objectives.vue?macro=true";
import { default as terms_45and_45conditions_45with_45privacy_45policynvaYvLc3FaMeta } from "/app/pages/pages/terms-and-conditions-with-privacy-policy.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as indexm3kBKhFtRPMeta } from "/app/pages/publications/[id]/index.vue?macro=true";
import { default as indexfe1H0UFRWEMeta } from "/app/pages/publications/index.vue?macro=true";
import { default as favoritesUCYh48gl9GMeta } from "/app/pages/settings/favorites.vue?macro=true";
import { default as groupApplication4EmP2DA4boMeta } from "/app/pages/settings/groupApplication.vue?macro=true";
import { default as marketingrkpAD6SBBdMeta } from "/app/pages/settings/marketing.vue?macro=true";
import { default as orders7ZQ3W3oIQcMeta } from "/app/pages/settings/orders.vue?macro=true";
import { default as profile8S52gQnHEjMeta } from "/app/pages/settings/profile.vue?macro=true";
import { default as subscriptionsLo3Vnu8xcRMeta } from "/app/pages/settings/subscriptions.vue?macro=true";
import { default as soonDWmBOSOVSXMeta } from "/app/pages/soon.vue?macro=true";
import { default as _ideHMqonfTGyMeta } from "/app/pages/vimeo-player/_id.vue?macro=true";
import { default as indexLvi90bO0w4Meta } from "/app/pages/vimeo-player/index.vue?macro=true";
export default [
  {
    name: "articles-id",
    path: "/articles/:id()",
    component: () => import("/app/pages/articles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-activate-account-token",
    path: "/auth/activate-account/token",
    component: () => import("/app/pages/auth/activate-account/token/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/token",
    component: () => import("/app/pages/auth/reset-password/token.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "bundles-id",
    path: "/bundles/:id()",
    component: () => import("/app/pages/bundles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/app/pages/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-checkout",
    path: "/cart/checkout",
    component: () => import("/app/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-order_status",
    path: "/cart/order_status",
    component: () => import("/app/pages/cart/order_status.vue").then(m => m.default || m)
  },
  {
    name: "end-year",
    path: "/end-year",
    component: () => import("/app/pages/end-year/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "FAQ",
    path: "/FAQ",
    component: () => import("/app/pages/FAQ.vue").then(m => m.default || m)
  },
  {
    name: "group-application-request",
    path: "/group-application-request",
    component: () => import("/app/pages/group-application-request/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "materials-id",
    path: "/materials/:id()",
    component: () => import("/app/pages/materials/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "materials",
    path: "/materials",
    component: () => import("/app/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "month-wallpapers",
    path: "/month-wallpapers",
    component: () => import("/app/pages/month-wallpapers/index.vue").then(m => m.default || m)
  },
  {
    name: "monthly-notebook",
    path: "/monthly-notebook",
    component: () => import("/app/pages/monthly-notebook/index.vue").then(m => m.default || m)
  },
  {
    name: "my-points",
    path: "/my-points",
    component: () => import("/app/pages/my-points/index.vue").then(m => m.default || m)
  },
  {
    name: "pages-_id.vue",
    path: "/pages/_id.vue",
    component: () => import("/app/pages/pages/_id.vue/index.vue").then(m => m.default || m)
  },
  {
    name: "pages-about",
    path: "/pages/about",
    component: () => import("/app/pages/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "pages-awareness-dictionary",
    path: "/pages/awareness-dictionary",
    component: () => import("/app/pages/pages/awareness-dictionary.vue").then(m => m.default || m)
  },
  {
    name: "pages",
    path: "/pages",
    component: () => import("/app/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pages-objectives",
    path: "/pages/objectives",
    component: () => import("/app/pages/pages/objectives.vue").then(m => m.default || m)
  },
  {
    name: "pages-terms-and-conditions-with-privacy-policy",
    path: "/pages/terms-and-conditions-with-privacy-policy",
    component: () => import("/app/pages/pages/terms-and-conditions-with-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "publications-id",
    path: "/publications/:id()",
    component: () => import("/app/pages/publications/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "publications",
    path: "/publications",
    component: () => import("/app/pages/publications/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-favorites",
    path: "/settings/favorites",
    component: () => import("/app/pages/settings/favorites.vue").then(m => m.default || m)
  },
  {
    name: "settings-groupApplication",
    path: "/settings/groupApplication",
    component: () => import("/app/pages/settings/groupApplication.vue").then(m => m.default || m)
  },
  {
    name: "settings-marketing",
    path: "/settings/marketing",
    component: () => import("/app/pages/settings/marketing.vue").then(m => m.default || m)
  },
  {
    name: "settings-orders",
    path: "/settings/orders",
    component: () => import("/app/pages/settings/orders.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    component: () => import("/app/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-subscriptions",
    path: "/settings/subscriptions",
    component: () => import("/app/pages/settings/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "soon",
    path: "/soon",
    component: () => import("/app/pages/soon.vue").then(m => m.default || m)
  },
  {
    name: "vimeo-player-_id",
    path: "/vimeo-player/_id",
    component: () => import("/app/pages/vimeo-player/_id.vue").then(m => m.default || m)
  },
  {
    name: "vimeo-player",
    path: "/vimeo-player",
    component: () => import("/app/pages/vimeo-player/index.vue").then(m => m.default || m)
  }
]