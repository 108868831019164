import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useAuthStore } from "~/stores/AuthStore";

export const useHomeStore = defineStore("home", {
  state: () => ({
    home: null,
    header: {},
    footer: null,
    homeBundles: null,
    countries: {},
    faq: {},
    events: {},
    monthWallpapers: {},
    monthNotebooks: {},
    monthWallpaper: {},
    monthNotebook: {},
    customerRatings: {},
    notifications: {},
    error: {},
    is_first_load: true,
    query: {},
    show_login_modal: false,
    loading: false,
  }),

  actions: {
    async fetchHome() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}home`,
          {
            headers: authStore.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.home = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        this.loading = false;
        console.error("home error", err);
      }
    },
    async homeHeader(promoQuery) {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}home-header`,
          {
            headers: authStore.auth.headers,
            params: { promo: promoQuery },
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.header = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        this.loading = false;
        if (err.response && err.response.data.status_code === 503) {
          this.error = err.response.data;
        }
      }
    },
    async homeFooter() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}home-footer`,
          {
            headers: authStore.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.footer = data.value ? data.value.data.footer : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchBundles(payload) {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}home-bundles`,
          {
            headers: authStore.auth.headers,
            params: {
              page: payload ? payload.page : null,
              per_page: payload ? payload.per_page : null,
            },
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.homeBundles = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchCountries(payload) {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      // try {
      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}countries`,
        {
          headers: authStore.auth.headers,
          params: { list: payload.list },
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        this.countries = data.value ? data.value.data : {};
      }
      // } catch (err) {
      //   this.loading = false;
      //   console.error(err);
      // }
    },
    async fetchFAQ() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}faqs`,
          {
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          this.loading = false;
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.faq = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchEvents() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}monthly-events`,
          {
            headers: authStore.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.events = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchWallpapers() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}month-wallpapers`,
          {
            headers: authStore.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.monthWallpapers = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchNotebooks() {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}month-notes`,
          {
            headers: authStore.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.monthNotebooks = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async fetchCustomerRatings(payload) {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}home-rates`,
          {
            headers: authStore.auth.headers,
            params: {
              page: payload ? payload.page : null,
              per_page: payload ? payload.per_page : null,
            },
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.customerRatings = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async getMonthWallpaper() {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true;
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}month-wallpapers/wallpaper`,
          {
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.monthWallpaper = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async getMonthNotebook() {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true;
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}month-notes/note`,
          {
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.monthNotebook = data.value ? data.value.data : {};
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async downloadMonthNote(payload) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        const response = await fetch(
          `${useRuntimeConfig().public.baseUrl}month-notes/download/${payload.id}`,
          {
            method: "GET",
            headers: {
              ...authStore.auth.headers,
              Cookies: payload.Cookies,
            },
          },
        );
        this.loading = false;
        const blob = await response.blob();
        let filename = "";
        const contentDisposition = response.headers.get("content-disposition");
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async contactus(formData) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;

      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}contact-us`,
        {
          method: "POST",
          body: formData,
          headers: authStore.auth.headers,
        },
      );
      this.loading = false;

      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return data.value;
      }
    },
    async addToMailingList(formData) {
      const authStore = useAuthStore();
      try {
        this.loading = true;

        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}mailing-list-subscribe`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        return data.value;
      } catch (e) {
        this.loading = false;
        return e.response ? e.response.data : {};
      }
    },

    async getSupportTeam() {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = false;

      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}contact-us-support-team-list`,
        {
          method: "GET",
          headers: authStore.auth.headers,
        },
      );
      this.loading = false;

      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return data.value;
      }
    },

    async fetchNotifications(payload) {
      const authStore = useAuthStore();
      try {
        this.loading = true;

        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}notifications`,
          {
            method: "GET",
            params: {
              page: payload ? payload.page : 1,
              per_page: payload ? payload.per_page : 5,
            },
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        if (!error.value) {
          this.notifications = data.value.data;
          return data.value.data;
        }
      } catch (e) {
        this.loading = false;
        return e.response ? e.response.data : {};
      }
    },

    async markAsRead(payload) {
      const authStore = useAuthStore();
      try {
        this.loading = true;

        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}notifications`,
          {
            method: "POST",
            params: {
              ids: payload.ids,
              read_all: payload.readAll ? payload.readAll : "",
            },
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        return data.value.data;
      } catch (e) {
        this.loading = false;
        return e.response ? e.response.data : {};
      }
    },

    async sendFCMToken(formData) {
      const authStore = useAuthStore();
      try {
        this.loading = true;

        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}notifications/setup-cloud-messaging`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );

        this.loading = false;
        return data.value.data;
      } catch (e) {
        this.loading = false;
        return e.response ? e.response.data : {};
      }
    },
  },
});
