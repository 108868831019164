export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Sumaya369"},{"name":"keywords","content":"د.سمية الناصر, تنمية, مدربة حياة"},{"name":"author","content":"الدكتورة سمية"},{"property":"og:title","content":"Sumaya369"},{"property":"og:description","content":"Sumaya369"},{"property":"og:image","content":"/assets/images/header/hero.jpg"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"Sumaya369"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/fav.png"},{"rel":"stylesheet","href":"https://goSellJSLib.b-cdn.net/v2.0.0/css/gosell.css"}],"style":[],"script":[{"src":"https://goSellJSLib.b-cdn.net/v2.0.0/js/gosell.js","defer":true}],"noscript":[],"charset":"utf-16","viewport":"width=500, initial-scale=1","title":"Sumaya369","htmlAttrs":{"lang":"ar"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false