import { useAuthStore } from '~/stores/AuthStore';
export default function useCheckRequestStatusCode() {
  async function checkStatusCode(status_code) {
    const authStore = useAuthStore();

    if (status_code === 401) {

      const formData = new FormData();
      // formData.append("device_id", localStorage.getItem("deviceId"));
      // const payload = {
      //   formData,
      //   device_id: localStorage.getItem("deviceId"),
      // };
      // await authStore.logout(payload); 
      
      const token = useCookie("token");
      
      token.value = null
      delete authStore.auth.headers.Authorization;
      authStore.auth.user = {};
      navigateTo({ path: '/' });
    }
  }
  return { checkStatusCode };
}
