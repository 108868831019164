import { defineNuxtPlugin } from '#app';
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';

// import StepProgress from 'vue-step-progress';
// import 'vue-step-progress/dist/main.css';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('step-progress' , StepProgress);
  
});
